
import { getSliceComponentProps } from '@prismicio/vue/components';
import seoPushAdapter from '@/slices/SeoPush/adapter';

export default {
  name: 'SeoPush',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  computed: {
    sliceData() {
      return seoPushAdapter(this.slice);
    },
  },
};
